import React, { useState } from "react";
import { Link } from "react-router-dom";

const Banner2 = () => {
  const [read, setread] = useState(false);
  return (
    <>
      <section className="relative md:py-24 py-16" id="about">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div className="md:col-span-6">
              <div className="lg:me-8">
                <div className="relative">
                  <img
                    src="/assets/images/about.jpg"
                    className="rounded-full shadow dark:shadow-gray-700"
                    alt=""
                  />
                  <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 mx-auto size-56 flex justify-center items-center bg-white dark:bg-slate-900 rounded-full shadow dark:shadow-gray-700">
                    <div className="text-center">
                      <span className="text-teal-500 text-2xl font-semibold mb-0 block">
                        <span
                          className="counter-value text-6xl font-semibold"
                          data-target={15}
                        >
                          20
                        </span>
                        +
                      </span>
                      <span className="font-semibold block mt-2">
                        Years <br /> Experience
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-6">
              <div className="lg:ms-8">
                <h6 className="text-teal-500 text-sm font-semibold uppercase mb-2">
                  Who Are We ?
                </h6>
                <h3 className="font-semibold text-2xl leading-normal mb-4">
                  We’re CI Innovations, developer of the The 3S FRAMEWORK
                  application.
                </h3>
                <p className="text-slate-400 max-w-xl mb-6">
                  With over 20 years’ experience in solving business critical
                  problems, we’ve learned that sustaining improvements can be
                  challenging. The sustaining challenges sparked the idea to
                  develop a framework centered on mastering sustaining. Our
                  step-by-step process dives deep into 30+ business critical
                  operations, including EHS, Communication & Recognition,
                  Employee Engagement, Problem Solving, Supply Chain Management,
                  New Product Introduction, Zero Loss, Quality at the Source,
                  Sales inventory and Operations Planning and more. Our goal is
                  to help you achieve & sustain World Class business operations.
                  You will track progress as you move from Level 1 to Level 5.
                  Be prepared to be highly engaged, as we have designed to
                  increase employee engagement levels.
                  {read && (
                    <p>
                      3S Means: Strategic, Success, Sustained. Our main purpose
                      is to develop innovative solutions that transform &
                      improve business operations in any industry. We believe if
                      you follow a proven framework that challenges your
                      operations/processes, it will engage the minds of
                      employees making them innovative thinkers. The 3S
                      Framework will do just that and continue to produce
                      results YOY.
                    </p>
                  )}
                </p>

                <Link
                  onClick={() => setread(!read)}
                  to="#"
                  className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white"
                >
                  Read {read ? "Less" : "More"}
                  <i className="mdi mdi-chevron-right align-middle ms-0.5" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
    </>
  );
};

export default Banner2;
