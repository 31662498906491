import React, { useState } from "react";
import { ImSpinner } from "react-icons/im";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../helper/http";
import { islogin } from "../../slice/auth/authslice";

const Login = () => {
  const [values, setValues] = useState({ email: "", password: "" });
  const [isemail, setisEmail] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onChange = (event) => {
    const { value, name } = event.target;
    setValues({ ...values, [name]: value });
  };
  const Checkemail = (event) => {
    const formdata = new FormData();
    formdata.append("email", values.email);
    setLoader(true);
    event.preventDefault();
    axiosInstance
      .post(`user/check/email`, formdata)
      .then((response) => {
        const { status, message } = response.data;
        if (status) {
          setisEmail(true);
          setLoader(false);
        } else {
          toast.error(message);
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const isLogin = (event) => {
    event.preventDefault();
    const formdata = new FormData();
    formdata.append("email", values.email);
    formdata.append("password", values.password);
    setLoader(true);
    axiosInstance
      .post(`user/login`, formdata)
      .then((response) => {
        console.log(response.data);
        const { status, message, token, data } = response.data;
        if (status) {
          localStorage.setItem("token", token);
          dispatch(islogin(data));
          toast.success(message);
          navigate("/");
          setLoader(false);
        } else {
          setisEmail(false);
          toast.error(message);
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };
  return (
    <>
      {/* component */}
      <div className="flex h-screen">
        <div className="w-full  lg:w-1/2 flex items-center justify-center ">
          <div className="max-w-md w-full p-6">
            <h1 className="text-3xl font-semibold mb-6 text-center">Sign in</h1>
            <div className="flex justify-center items-center">
              <img
                src="/assets/images/logo-light.png"
                alt="logo"
                className="w-1/2 text-center mb-3"
              />
            </div>
            {/* <h1 className="text-sm font-semibold mb-6  text-center">
              Join to Our Community with all time access and free{" "}
            </h1> */}

            <form
              onSubmit={isemail ? isLogin : Checkemail}
              className="space-y-4"
            >
              {/* Your form elements go here */}

              <div>
                <label htmlFor="email" className="block text-sm font-medium ">
                  Email
                </label>
                <input
                  onChange={onChange}
                  value={values?.email}
                  type="text"
                  disabled={isemail}
                  id="email"
                  name="email"
                  className="mt-1 p-2 w-full border bg-transparent rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                />
              </div>
              {isemail && (
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium "
                  >
                    Password
                  </label>
                  <input
                    onChange={onChange}
                    value={values?.password}
                    type="password"
                    id="password"
                    name="password"
                    className="mt-1 p-2 w-full border bg-transparent rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                  />
                </div>
              )}
              <div>
                <button
                  type="submit"
                  disabled={loader}
                  className="w-full flex justify-center items-center bg-black text-white p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors duration-300"
                >
                  {loader ? (
                    <ImSpinner className="animate-spin" size={25} />
                  ) : (
                    "Sign in"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
