import React from "react";
import { IoIosTrendingUp } from "react-icons/io";
import { FaDollarSign } from "react-icons/fa";
import {
  FiBell,
  FiBox,
  FiCamera,
  FiCommand,
  FiCompass,
  FiMonitor,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Grow Your Business",
    icon: <IoIosTrendingUp className="size-6 rotate-45" />,
    icon2: (
      <IoIosTrendingUp className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description: `Strategic approach that can significantly contribute to the growth of a business. `,
  },
  {
    title: "Best in Class Financials",
    icon: <FaDollarSign className="size-6 rotate-45" />,
    icon2: (
      <FaDollarSign className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description: `Improve value stream
    for efficient operations
    and healthy balance
    sheets.`,
  },
  {
    title: "Customer Focused",
    icon: <FiCompass className="size-6 rotate-45" />,
    icon2: (
      <FiCompass className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description:`Highly focused on improving the customer’s experience and changing their beliefs`
  },
  {
    title: "Supply Chain Effectiveness",
    icon: <FiCommand className="size-6 rotate-45" />,
    icon2: (
      <FiCommand className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description:`Drives innovative thinking across the Supply Chain to deliver World Class results`
  },
  {
    title: "World Class Safety",
    icon: <FiBox className="size-6 rotate-45" />,
    icon2: (
      <FiBox className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description:"Pursue Excellence in safety to deliver World Class Safety Results."
  },
  {
    title: "Employee Focused",
    icon: <FiCamera className="size-6 rotate-45" />,
    icon2: (
      <FiCamera className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description:"Designed to engage all levels of employees, break down the silos, and promote crossfunctional collaboration"
  },
  {
    title: "Drive Innovation",
    icon: <FiBell className="size-6 rotate-45" />,
    icon2: (
      <FiBell className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description:"Enhanced focus on utilization of employee’s intellect to drive innovation across all operations"
  },
  {
    title: "Achieve World Class Results",
    icon: <FiMonitor className="size-6 rotate-45" />,
    icon2: (
      <FiMonitor className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500" />
    ),
    description:"10–20-year competitive advantage by challenging maturity & improving over 30 business critical areas"
  },
];

const Banner4 = () => {
  return (
    <>
      <>
        <section
          className="relative md:py-24 py-16 bg-slate-50 dark:bg-slate-800"
          id="solutions"
        >
          <div className="container relative">
            <div className="grid grid-cols-1 pb-6 text-center">
              <h3 className="font-semibold text-2xl leading-normal mb-4">
                The 3S FRAMEWORK SOLUTION
              </h3>
              <p className="text-slate-400 max-w-xl mx-auto">
                30 + business critical areas of focus
              </p>
            </div>
            {/*end grid*/}
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
              {data.map((e) => (
                <div className="group rounded-md shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-fit">
                  <div className="flex items-center justify-center size-14 -rotate-45 bg-gradient-to-r from-transparent to-teal-500/10 text-teal-500 text-center rounded-full group-hover:bg-teal-500/10 duration-500">
                    {e.icon}
                  </div>
                  <div className="content mt-6 relative z-1">
                    <Link
                      to="#"
                      className="title text-lg font-semibold hover:text-teal-500"
                    >
                      {e.title}
                    </Link>
                    <p className="text-slate-400 mt-3">{e?.description}</p>
                    {/* <div className="mt-6">
                      <Link to="#" className="text-teal-500">
                        Read More{" "}
                        <i className="mdi mdi-chevron-right align-middle" />
                      </Link>
                    </div> */}
                  </div>
                  <div className="absolute bottom-0 -end-16">
                    <i className="size-48 text-teal-500 opacity-[0.04] dark:opacity-[0.04] group-hover:opacity-10 duration-500">
                      {e.icon2}
                    </i>
                  </div>
                </div>
              ))}
              {/*end feature content*/}

              {/*end feature content*/}
            </div>
            {/*end grid*/}
          </div>
          {/*end container*/}
        </section>
        {/*end section*/}
      </>

      {/*end section*/}
    </>
  );
};

export default Banner4;
