import axios from "axios";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  baseURL: "https://3sframeworkapi.dev-nuh.xyz/api/",
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const Data = error?.response?.data;
    const errors = Data.error;
    if (errors) {
      const keys = Object.keys(errors);
      keys.map((key) =>
        errors[key].forEach((errorMessage) => {
          toast.error(errorMessage);
        })
      );
    } else {
      toast.error(Data.message);
    }
    if (Data.message === "Unauthenticated.") {
      localStorage.clear();
      window.location.reload();
      window.location.href="/login"
    } else {
    }
    // return Promise.reject(error);
  }
);
