import React from "react";
import Banner from "../components/banner";
import Banner10 from "../components/banner10";
import Banner2 from "../components/banner2";
import Banner4 from "../components/banner4";
import Banner5 from "../components/banner5";
import Banner7 from "../components/banner7";
import Banner8 from "../components/banner8";
import Layout from "../layout/layout";

const Home = () => {
  return (
    <Layout>
      <Banner />
      <Banner2 />
      <Banner4 />
      <Banner5 />
      <Banner7 />
      <Banner8 />
      <Banner10 />
    </Layout>
  );
};

export default Home;
