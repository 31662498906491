import "flowbite";
import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiLoginCircleLine, RiLogoutCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../slice/auth/authslice";
const Header = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const path = window.location.pathname;
  return (
    <nav className="navbar" id="navbar">
      <div className="container relative flex flex-wrap items-center justify-between">
        <Link className="navbar-brand md:me-8" to="/">
          <span className="inline-block dark:hidden">
            <img
              src="/assets/images/logo-dark.png"
              className="l-dark"
              alt=""
              width={100}
            />
            <img
              src="/assets/images/logo-light.png"
              className="l-light"
              alt=""
              width={100}
            />
          </span>
          <img
            src="/assets/images/logo-light.png"
            className="hidden dark:inline-block"
            alt=""
            width={100}
          />
        </Link>
        <div className="nav-icons flex items-center lg_992:order-2 ms-auto md:ms-8">
          {/* Navbar Button */}
          <ul className="list-none menu-social mb-0">
            <li className="inline">
              {isAuthenticated ? (
                <>
                  <button
                    className="h-8 px-4 mb-1 text-[12px] tracking-wider inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white uppercase"
                    type="button"
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                  >
                    Profile&nbsp;
                    <FaUserAlt />
                  </button>
                  {toggle && (
                    <div className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:!bg-gray-700 dark:divide-gray-600">
                      <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                        <div>{`${user?.first_name} ${user?.last_name}`}</div>
                        <div className="font-medium truncate">
                          {user?.email}
                        </div>
                      </div>
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownInformationButton"
                      >
                        <li>
                          <NavLink
                            to="/module"
                            className="flex gap-2 items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            <LuLayoutDashboard />
                            Dashboard
                          </NavLink>
                        </li>
                      </ul>
                      <div className="py-2">
                        <NavLink
                          to="#"
                          onClick={() => dispatch(logout())}
                          className="flex gap-2 items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        >
                          <RiLogoutCircleLine /> Log out
                        </NavLink>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <NavLink
                    to="/login"
                    className="h-8 px-4 text-[12px] tracking-wider inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white uppercase"
                  >
                    User Login&nbsp;
                    <RiLoginCircleLine size={20} />
                  </NavLink>
                  &nbsp;&nbsp;
                  <a
                    href="https://company.3sframework.org/"
                    className="h-8 px-4 text-[12px] tracking-wider inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white uppercase"
                  >
                    Company Login&nbsp;
                    <RiLoginCircleLine size={20} />
                  </a>
                </>
              )}
            </li>
          </ul>
          {/* Navbar Collapse Manu Button */}
          {/* <button
            data-collapse="menu-collapse"
            type="button"
            className="collapse-btn inline-flex items-center ms-2 text-dark dark:text-white lg_992:hidden"
            aria-controls="menu-collapse"
            aria-expanded="false"
          >
            <span className="sr-only">Navigation Menu</span>
            <i className="mdi mdi-menu text-[24px]" />
          </button> */}
        </div>
        {/* Navbar Manu */}
        {path !== "/module" && (
          <div
            className="navigation lg_992:order-1 lg_992:flex hidden ms-auto"
            id="menu-collapse"
          >
            <ul
              className="navbar-nav nav-light sidebar-nav "
              id="navbar-navlist"
            >
              <li className="nav-item">
                <a className="nav-link active " href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#solutions">
                  SOLUTIONS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#users">
                  Users
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#pricing">
                  Pricing
                </a>
              </li>
              {/* <li className="nav-item">
              <NavLink className="nav-link" to="#blog">
                Blogs
              </NavLink>
            </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact us
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
