import React, { useState } from "react";
import { axiosInstance } from "../helper/http";
import { toast } from "react-toastify";

const Banner10 = () => {
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState();
  const onChange = (event) => {
    const { value, name } = event.target;
    setValues({ ...values, [name]: value });
  };
  const Contact = (event) => {
    event.preventDefault();
    const formdata = new FormData();
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        formdata.append(key, values[key]);
      }
    }
    setLoader(true);
    axiosInstance
      .post("user/contact", formdata)
      .then((response) => {
        setLoader(false);
        toast.success(response.data.message);
        document.getElementById("myForm").reset();
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  return (
    <section
      className="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800"
      id="contact"
    >
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="font-semibold text-2xl leading-normal mb-4">
            Get in touch{" "}
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">Ask us a Question!</p>
        </div>
        {/*end grid*/}
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
          <div className="lg:col-span-7 md:col-span-6">
            <img src="/assets/images/contact.svg" alt="" />
          </div>
          <div className="lg:col-span-5 md:col-span-6">
            <div className="lg:ms-5">
              <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                <form id="myForm" onSubmit={Contact}>
                  <p className="mb-0" id="error-msg" />
                  <div id="simple-msg" />
                  <div className="grid lg:grid-cols-12 grid-cols-1 gap-3">
                    <div className="lg:col-span-6">
                      <label htmlFor="name" className="font-semibold">
                        Your Name:
                      </label>
                      <input
                        required
                        name="name"
                        id="name"
                        type="text"
                        className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        onChange={onChange}
                        placeholder="Name :"
                      />
                    </div>
                    <div className="lg:col-span-6">
                      <label htmlFor="email" className="font-semibold">
                        Your Email:
                      </label>
                      <input
                        required
                        name="email"
                        id="email"
                        type="email"
                        className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        onChange={onChange}
                        placeholder="Email :"
                      />
                    </div>
                    <div className="lg:col-span-12">
                      <label htmlFor="subject" className="font-semibold">
                        Your Question:
                      </label>
                      <input
                        required
                        name="subject"
                        id="subject"
                        className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        onChange={onChange}
                        placeholder="Subject :"
                      />
                    </div>
                    <div className="lg:col-span-12">
                      <label htmlFor="message" className="font-semibold">
                        Your Comment:
                      </label>
                      <textarea
                        name="message"
                        required
                        id="message"
                        className="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                        onChange={onChange}
                        placeholder="Message :"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    disabled={loader}
                    className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white mt-2"
                  >
                    {loader ? "Sending..." : "Send Message"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end container*/}
    </section>
  );
};

export default Banner10;
