import "flowbite";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import RequireAuth from "./helper/RequiredAuth";
import Login from "./pages/auth/login";
import Home from "./pages/home";
import Module from "./pages/module";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/module"
          element={
            <RequireAuth>
              <Module />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}



export default App;
